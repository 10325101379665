<template>
  <div class="h-screen flex items-center justify-center">
    <div class="dark:text-white" v-if="error">
      {{ error }}
    </div>
    <div v-else><img class="w-16" :src="require('@/assets/img/auth/spinner-green.svg')" /></div>
  </div>
</template>

<script>
import { ref } from "vue"
import useLogin from "@/composables/useLogin"
import { useRouter } from "vue-router"
import { useStore } from "vuex"

export default {
  name: "Login",
  setup() {
    const { error, login, isPending } = useLogin()
    const router = useRouter()
    const store = useStore()

    if (store.state.dark) {
      document.body.classList.add("dark")
    }

    const autoSignIn = async () => {
      const res = await login("sampleaccount@email.com", "ZQp]*u5-ug+r2y7h")
      if (!error.value) {
        store.commit("setUser", res.user)
        router.push({ name: "Dashboard" })
      }
    }

    autoSignIn()

    return { error }
  }
}
</script>

<style scoped lang="postcss">
input {
  @apply block w-full py-2 rounded focus:outline-none;
}
</style>
