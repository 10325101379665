import firebase from 'firebase/app'
import functions from 'firebase/functions'
import 'firebase/firestore'
import 'firebase/auth'


const firebaseConfig = {
    apiKey: "AIzaSyDreCwaOMF-wYBy8Ybuz6LQb0YO-H_wzOA",
    authDomain: "robinhood-clone-vue-tailwind.firebaseapp.com",
    projectId: "robinhood-clone-vue-tailwind",
    storageBucket: "robinhood-clone-vue-tailwind.appspot.com",
    messagingSenderId: "778744941516",
    appId: "1:778744941516:web:c101f369bbf829d26aeb69"
};

// init firebase
firebase.initializeApp(firebaseConfig)

// init services
const projectFirestore = firebase.firestore()
const projectAuth = firebase.auth()
const projectFunctions = firebase.functions()

// timestamp
const timestamp = firebase.firestore.FieldValue.serverTimestamp

export { projectFirestore, projectAuth, projectFunctions, timestamp }